<template>
  <!-- 路内停车场 —— 车位信息 -->
  <div class="ParkingInformation">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="停车场：">
        <el-select popper-class="my-select" v-model="queryForm.parking_id" filterable clearable default-first-option
          placeholder="请选择停车场" @change="onSubmit">
          <el-option v-for="item in getAllParkingList(1)" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车位编号：">
        <el-input v-model="queryForm.space_number" @keyup.enter.native="onSubmit" maxlength="15"
          @input="(e) => (queryForm.space_number = validSpacingSpecial(e))" placeholder="请输入车位编号"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="车位信息">
      <template slot="heard-right">
        <div>
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('b75d3844e47a4704b3593a79')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="isBatchAddDialogVisible = true"
            v-if="this.getUserInfo.user_name != 'admin' && isShowBtn('8acf10d41cc844928a83e518')">新增</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div class="box-card">
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="warning" icon="bianji" hint="编辑" @click="editDialog(row)"
              v-if="isShowBtn('483758bd1b764f8dad4be4fe')"></lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" align="center" header-align="center" tooltip-effect="dark"
          style="width: 100%" border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="space_number" label="车位编号" min-width="180"></el-table-column>
          <el-table-column prop="parking_name" label="停车场" min-width="150"></el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="editDialog(scope.row)"
                v-if="isShowBtn('483758bd1b764f8dad4be4fe')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 新增车位对话框 -->
    <lebo-dialog append-to-body title="新增车位" :isShow="isBatchAddDialogVisible" width="50%"
      @close="closeBatchAddDialogVisible()" footerSlot closeOnClickModal>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="140px" class="demo-ruleForm"
        style="padding: 0 50px" v-loading="isLoading">
        <el-form-item label="停车场：" prop="parking_id">
          <el-select popper-class="my-select" v-model="addForm.parking_id" filterable clearable default-first-option
            placeholder="请选择停车场" @change="spaceParkingChange">
            <el-option v-for="item in getAllParkingList(1)" :key="item.parking_id" :label="item.parking_name"
              :value="item.parking_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车位添加方式：" prop="mode">
          <el-select popper-class="my-select" placeholder="请选择车位添加方式" v-model="addForm.mode">
            <el-option label="手动输入" value="手动输入"></el-option>
            <el-option label="自动生成" value="自动生成"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车位编号：" v-if="addForm.mode == '手动输入'" prop="number">
          <el-input v-model="addForm.number" placeholder="请输入车位编号" maxlength="15"
            @input="(e) => (addForm.number = validSpacingSpecial(e))"></el-input>
        </el-form-item>
        <div v-else>
          <el-form-item label="车位编号规则：" prop="rules">
            <el-select popper-class="my-select" placeholder="请选择车位编号规则" v-model="addForm.rules">
              <el-option label="纯数字" value="纯数字"></el-option>
              <el-option label="字母+数字" value="字母+数字"></el-option>
              <el-option label="字母+字符+数字" value="字母+字符+数字"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车位编号位数：" prop="digit">
            <el-input v-model="addForm.digit" placeholder="请输入车位编号位数" maxlength="2"
              @input="(e) => (addForm.digit = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="字母：" v-if="addForm.rules != '纯数字'" prop="alphabet">
            <el-input v-model="addForm.alphabet" placeholder="请输入字母" maxlength="4"
              @input="(e) => (addForm.alphabet = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="字符：" v-if="addForm.rules == '字母+字符+数字'" prop="symbol">
            <el-input v-model="addForm.symbol" placeholder="请输入字符" maxlength="4"
              @input="(e) => (addForm.symbol = validSpacing(e))"></el-input>
          </el-form-item>
          <el-form-item label="数字：" prop="start">
            <div style="display: inline-block">
              从
              <el-form-item style="display: inline-block; margin: 0">
                <el-input v-model="addForm.start" maxlength="5" style="width: 100px; margin: 0 10px"
                  @input="(e) => (addForm.start = validSpace(e))"></el-input>
              </el-form-item>
              开始，按递增规则生成
              <el-form-item prop="end" style="display: inline-block; margin: 0">
                <el-input v-model="addForm.end" maxlength="5" style="width: 100px; margin: 0 10px"
                  @input="(e) => (addForm.end = validSpace(e))"></el-input>
              </el-form-item>
              个车位
            </div>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isBatchAddDialogVisible = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="batchAdd()">保 存</lbButton>
      </span>
      <lebo-dialog append-to-body width="60%" title="车位详情" :isShow="isDetailsDialogVisible" @open="isLoading = false"
        @close="isLoading = false, isDetailsDialogVisible = false" footerSlot>
        <div>共生成 {{ detailsList.length }} 个车位</div>
        <el-tag v-for="(item, index) in detailsList" :key="item" closable @close="closeTag(index)">{{ item }}</el-tag>
        <span slot="footer" class="dialog-footer">
          <!-- <lbButton type="goBack" icon="back" @click="isDetailsDialogVisible = false">返 回</lbButton> -->
          <lbButton type="default" fill icon="confirm" @click="Add()">保 存</lbButton>
        </span>
        <lebo-dialog append-to-body width="60%" title="失败原因" :isShow="isError" @close="closeError()" footerSlot
          style="z-index: 9999;">
          <!-- <div>共生成 {{detailsList.length}} 个车位</div> -->
          <p>
            批量新增{{ errorData.totalCount }}个车位，成功{{
              errorData.successCount
            }}个，失败{{ errorData.errorCount }}个
          </p>
          <p>失败原因：车位编号重复，无法重复添加</p>
          <p>重复的车位编号：{{ errorData.strCause }}</p>
          <!-- <el-tag v-for="(item, index) in detailsList" :key="item" closable>{{item}}</el-tag> -->
          <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="isError = false">关 闭</el-button> -->
            <lbButton type="default" fill icon="confirm" @click="closeError();
            onSubmit();">保 存</lbButton>
          </span>
        </lebo-dialog>
      </lebo-dialog>
    </lebo-dialog>
    <!-- 编辑车位 对话框 -->
    <lebo-dialog append-to-body title="编辑车位" :isShow="isEditDialogVisible" width="30%"
      @close=" closebatchEditDialogVisible()" footerSlot>
      <el-form :model="EditForm" :rules="EditFormRules" ref="EditFormRef" label-width="120px" class="demo-ruleForm">
        <el-form-item label="停车场：" prop="parking_name">
          <el-input maxlength="20" v-model="EditForm.parking_name" :disabled="true"
            @input="(e) => (EditForm.parking_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="车位编号：" prop="space_number">
          <el-input v-model="EditForm.space_number" maxlength="15"
            @input="(e) => (EditForm.space_number = validSpacingSpecial(e))" placeholder="请输入车位编号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click=" isEditDialogVisible = false ">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click=" batchEdit()">保 存</lbButton>
      </span>
    </lebo-dialog>
  </div>
</template>

<script>
import {
  getParkingSpaceList,
  delParkingSpace,
  addParkingSpace,
  updateParkingSpace,
  getParkingJurisdiction,
  getParkingSpaceIsReuse
} from '@/api/curbParkapi'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  data () {
    // 手动输入车位编号规则
    var validateNumber = (rule, value, callback) => {
      const numReg = /^[\u4e00-\u9fa5]$/
      if (!numReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    // 是否为数字
    var isNumber = (rule, value, callback) => {
      const isNumberReg = /^\+?[1-9][0-9]*$/
      if (isNumberReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    // 是否为字母
    var isAlphabet = (rule, value, callback) => {
      const isAlphabetReg = /^\+?[a-zA-Z][a-zA-Z]*$/
      if (isAlphabetReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    // 是否为字符
    var isSymbol = (rule, value, callback) => {
      const isSymbolReg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/
      if (!isSymbolReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    return {
      // 查询表单
      queryForm: {
        space_number: '',
        parking_id: '',
        PageSize: 10,
        PageIndex: 1
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 是否展示 新增车位 对话框
      isBatchAddDialogVisible: false,
      //   停车场列表
      spaceParkingList: [],
      // 新增表单
      addForm: {
        parking_id: '',
        parking_name: '',
        mode: '手动输入',
        number: '',
        rules: '纯数字',
        digit: '',
        alphabet: '',
        symbol: '',
        start: '',
        end: ''
      },
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '车位编号',
            prop: 'space_number'
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      //  新增 表单验证规则
      addFormRules: {
        parking_id: [
          { required: true, message: '请选择停车场', trigger: 'blur' }
        ],
        mode: [
          { required: true, message: '请选择车位添加方式', trigger: 'blur' }
        ],
        // 非中文
        number: [
          { required: true, message: '请输入车位编号', trigger: 'blur' },
          { validator: validateNumber, message: '请输入数字或字母或字符' },
          { max: 15, message: '车位编号在15个字符以内', trigger: 'blur' }
        ],
        rules: [
          { required: true, message: '请选择车位编号规则', trigger: 'change' }
        ],
        digit: [
          { required: true, message: '请输入车位编号位数', trigger: 'blur' },
          { validator: isNumber, message: '车位编号位数必须为正整数' }
        ],
        alphabet: [
          { required: true, message: '请输入字母', trigger: 'blur' },
          { validator: isAlphabet, message: '必须为字母' }
        ],
        symbol: [
          { required: true, message: '请输入字符', trigger: 'blur' },
          { validator: isSymbol, message: '必须为字符' }
        ],
        start: [
          { required: true, message: '请输入开始数字', trigger: 'blur' },
          { validator: isNumber, message: '必须为正整数', trigger: 'blur' }
        ],
        end: [
          { required: true, message: '请输入车位个数', trigger: 'blur' },
          { validator: isNumber, message: '必须为正整数', trigger: 'blur' }
        ]
      },
      // 车位详情 对话框
      isDetailsDialogVisible: false,
      // 车位列表
      detailsList: [],
      // 自动生成车位编号后的提示框
      isError: false,
      // 失败数据
      errorData: {},
      // 是否展示 编辑车位 对话框
      isEditDialogVisible: false,
      isLoading: false,
      // 编辑车位 表单
      EditForm: {
        parking_id: '',
        parking_name: '',
        space_number: '',
        parking_space_id: ''
      },
      // 编辑车位 表单验证规则
      EditFormRules: {
        parking_name: [{ required: true, trigger: 'blur' }],
        space_number: [
          { required: true, message: '请输入车位编号', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.fnGetParkingSpaceList()
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters([
      'getOptions',
      'getWayName',
      'getUserInfo',
      'getAllParkingList'
    ])
    // this.$store.state.parkingList
  },
  mounted () {
    this.spaceParkingList = this.$store.state.parkingList
    // console.log(this.$store.state.parkingList);
    // this.carModelList = this.$store.getters.getOptions('ColorType')
  },
  watch: {
    isLoading (val, old) {
      // console.log('watch---isLoading---', val);
    }
  },
  methods: {
    // 获取车位列表
    async fnGetParkingSpaceList () {
      const res = await getParkingSpaceList(this.queryForm)
      // console.log(res);
      if (res && res.Code === 200) {
        // console.log(this.queryForm);
        this.tableData = res.Data.DataList
        this.total = res.Data.TotalCount
      } else {
        // console.log(this.queryForm);
        this.tableData = []
        this.total = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetParkingSpaceList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetParkingSpaceList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetParkingSpaceList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fnGetParkingSpaceList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetParkingSpaceList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的车位编号, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            var obj = {}
            var arr = []
            // this.$msg.success('删除成功!');
            for (var i = 0; i < this.multipleSelection.length; i++) {
              arr.push(this.multipleSelection[i]._id)
              if (arr.length === this.multipleSelection.length) {
                obj._ids = arr
                this.fnDelParkingSpace(obj)
              }
            }
          })
          .catch(() => {
            this.$msg.info('已取消删除')
          })
      } else {
        this.$msg.warning('请选择要删除的车位编号！')
      }
    },
    // 删除车位请求
    async fnDelParkingSpace (obj) {
      const res = await delParkingSpace(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetParkingSpaceList()
    },
    // 关闭新增停车场对话框，重置数据
    closeBatchAddDialogVisible () {
      this.isBatchAddDialogVisible = false
      this.$refs.addFormRef.resetFields()
      this.addForm.parking_id = ''
    },
    // 关闭车位名字重复报错弹框
    closeError () {
      this.isError = false
      this.isDetailsDialogVisible = false
      this.isBatchAddDialogVisible = false
    },
    // 获取停车场列表
    // async fngetParkingJurisdiction() {
    //   const res = await getParkingJurisdiction()
    // console.log(res)
    //   this.spaceParkingList = res.Data.have_parking_lot
    // },
    // 当新增车位停车场下拉框发生改变时
    spaceParkingChange (val) {
      // console.log(val);
      var arr = this.spaceParkingList.filter((item) => {
        return val === item.parking_id
      })
      this.addForm.parking_name = arr[0].parking_name
    },
    // 判断车位编号是否重复
    async fngetParkingSpaceIsReuse (type) {
      // type = 1 手动新增车位
      // type = 2 编辑车位
      const res = await getParkingSpaceIsReuse({
        parking_space_id: type === 1 ? '' : this.EditForm.parking_space_id,
        parking_id:
          type === 1 ? this.addForm.parking_id : this.EditForm.parking_id,
        space_number:
          type === 1 ? this.addForm.number : this.EditForm.space_number
      })
      // console.log(res);
      if (res && res.Code === 200) {
        var obj
        if (type === 1) {
          obj = {
            parking_id: this.addForm.parking_id,
            parking_name: this.addForm.parking_name,
            list_space_number: [this.addForm.number]
          }
          this.fnAddParkingSpace(obj)
          this.isBatchAddDialogVisible = false
        } else {
          obj = {
            _id: this.EditForm.parking_space_id,
            space_number: this.EditForm.space_number
          }
          this.fnupdateParkingSpace(obj)
          this.isEditDialogVisible = false
        }
      }
    },
    // 新增车位
    batchAdd () {
      if (this.isLoading) {
        return
      }
      // 对新增表单进行校验
      const that = this
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          this.automaticGeneration()
        } else {
          return false
        }
      })
    },
    // 自动生成车位
    automaticGeneration () {
      var that = this
      if (this.addForm.mode === '自动生成') {
        this.isLoading = true
        if (this.addForm.rules === '纯数字') {
          this.getNum()
          // setTimeout(() => {
          //   that.isDetailsDialogVisible = true
          // }, 300)
        } else if (this.addForm.rules === '字母+数字') {
          this.getNumAndAlphabet()
          // setTimeout(() => {
          //   that.isDetailsDialogVisible = true
          // }, 300)
        } else if (this.addForm.rules === '字母+字符+数字') {
          this.getNumAndAlphabetAndSymbol()
          // setTimeout(() => {
          //   that.isDetailsDialogVisible = true
          // }, 300)
        }
        that.isDetailsDialogVisible = true
      } else {
        this.fngetParkingSpaceIsReuse(1)
      }
      this.isLoading = false
    },
    // 纯数字
    getNum () {
      this.detailsList = []
      var data = []
      var max = parseInt(this.addForm.end) + parseInt(this.addForm.start)
      for (var i = parseInt(this.addForm.start); i < max; i++) {
        data.push(i.toString())
      }
      var str = ''
      for (var a = 0; a < this.addForm.digit; a++) {
        str += '0'
      }
      // console.log(str);
      var newArr = []
      for (var b = 0; b < data.length; b++) {
        newArr[b] =
          str.substr(0, this.addForm.digit - data[b].length) + data[b]
      }
      // console.log(newArr);
      // console.log(this.addForm.digit);
      this.detailsList = newArr.filter((item) => {
        //  // console.log(item);
        //  // console.log(item.length);
        return item.length <= this.addForm.digit
      })
      // console.log(this.detailsList);
    },
    // 字母+数字
    getNumAndAlphabet () {
      this.detailsList = []
      var data = []
      var max = parseInt(this.addForm.end) + parseInt(this.addForm.start)
      var str = ''
      var length = parseInt(this.addForm.digit) - this.addForm.alphabet.length
      // console.log(length);
      for (var a = 0; a < length; a++) {
        str += '0'
      }
      //  // console.log(str)
      for (var j = parseInt(this.addForm.start); j < max; j++) {
        data.push(j.toString())
      }
      var newArr = []
      for (var b = 0; b < data.length; b++) {
        newArr[b] = str.substr(0, length - data[b].length) + data[b]
      }
      newArr = newArr.filter((item) => {
        return item.length <= length
      })
      // console.log(newArr)
      for (var i = 0; i < newArr.length; i++) {
        this.detailsList.push(this.addForm.alphabet + newArr[i])
      }
      // console.log(this.detailsList);
    },
    // 字母+字符+数字
    getNumAndAlphabetAndSymbol () {
      this.detailsList = []
      var data = []
      var max = parseInt(this.addForm.end) + parseInt(this.addForm.start)
      var str = ''
      var length =
        parseInt(this.addForm.digit) -
        this.addForm.alphabet.length -
        this.addForm.symbol.length
      // console.log(length);
      for (var a = 0; a < length; a++) {
        str += '0'
      }
      for (var j = parseInt(this.addForm.start); j < max; j++) {
        data.push(j.toString())
      }
      var newArr = []
      for (var b = 0; b < data.length; b++) {
        newArr[b] = str.substr(0, length - data[b].length) + data[b]
      }
      newArr = newArr.filter((item) => {
        return item.length <= length
      })
      for (var i = 0; i < newArr.length; i++) {
        this.detailsList.push(
          this.addForm.alphabet + this.addForm.symbol + newArr[i]
        )
      }
      // console.log(this.detailsList);
    },
    // 删除某个车位
    closeTag (index) {
      this.detailsList.splice(index, 1)
    },
    // 自动新增车位
    Add () {
      var obj = {
        parking_id: this.addForm.parking_id,
        parking_name: this.addForm.parking_name,
        list_space_number: this.detailsList
      }
      this.fnAddParkingSpace(obj)
    },
    // 新增车位请求
    async fnAddParkingSpace (obj) {
      const res = await addParkingSpace(obj)
      // console.log(res.Data.successCount);
      // console.log(res.Data.totalCount);
      // console.log(this.addForm.mode);
      this.addForm = { // 新增完成后还原默认数据
        parking_id: '',
        parking_name: '',
        mode: '手动输入',
        number: '',
        rules: '纯数字',
        digit: '',
        alphabet: '',
        symbol: '',
        start: '',
        end: ''
      }
      if (res.Data.successCount < res.Data.totalCount) {
        this.isError = true
        this.errorData = {
          totalCount: res.Data.totalCount,
          successCount: res.Data.successCount,
          errorCount: res.Data.errorCount,
          strCause: res.Data.strCause
        }
      } else {
        this.onSubmit()
        this.isDetailsDialogVisible = false
        this.isBatchAddDialogVisible = false
      }
    },
    // 编辑车位对话框
    editDialog (row) {
      // console.log(row);
      this.isEditDialogVisible = true
      this.EditForm.parking_id = row.parking_id
      this.EditForm.parking_name = row.parking_name
      this.EditForm.space_number = row.space_number
      this.EditForm.parking_space_id = row._id
    },
    // 关闭编辑车位对话框，重置数据
    closebatchEditDialogVisible () {
      this.isEditDialogVisible = false
      this.$refs.EditFormRef.resetFields()
    },
    // 编辑车位
    batchEdit () {
      // 对编辑表单进行校验
      this.$refs.EditFormRef.validate((valid) => {
        if (valid) {
          this.fngetParkingSpaceIsReuse(2)
        } else {
          return false
        }
      })
    },
    // 编辑车位请求
    async fnupdateParkingSpace (obj) {
      const res = await updateParkingSpace(obj)
      // console.log(res);
      this.fnGetParkingSpaceList()
    }
  }
}
</script>

<style scoped lang="less">
.ParkingInformation {
  margin-top: 20px;
}

.demo-form-inline {
  /* float: left; */
  display: flex;
  justify-content: start;
}

// .el-input__inner {
//   height: 36px;
//   line-height: 36px;
// }
.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}

.el-select {
  width: 100%;
}

.el-tag {
  margin-top: 15px;
  margin-right: 15px;
}

.demo-ruleForm {
  /deep/ .el-form-item__content {
    text-align: left !important;
  }
}</style>
