/**
 * 路内停车场相关请求
 */
import axios from './http';
// 停车场列表查询
export const getParkingList = (params) => axios.get('/ParkingLotApi/lnParking/GetPageList', { params: params })
// 新增停车场
export const addParkingInfo = (data) => axios.post('/ParkingLotApi/lnParking/Add', data)
// 编辑停车场
export const updateParkingInfo = (data) => axios.post('/ParkingLotApi/lnParking/Update', data)
// 判断停车场名称是否重复
export const getParkingNameIsReuse = (params) => axios.get('/ParkingLotApi/lnParking/VerifyLnparkingNameIsReuse', { params: params })
// 删除停车场
export const delParkingInfo = (data) => axios.post('/ParkingLotApi/lnParking/Delete', data)
// 设置停车场开启或关闭状态
export const setParkingStatus = (data) => axios.post('/ParkingLotApi/lnParking/SetlnParkingStatus', data)
// 查看停车场基本信息
export const getParkingDetails = (params) => axios.get('/ParkingLotApi/lnParking/GetDetails', { params: params })
// 查看停车场车位信息
export const getParkingSpaceDetailList = (params) => axios.get('/ParkingLotApi/lnParking/GetParkingSpaceDetailPageList', { params: params })
// 查看停车场计费方案
export const getParkingCostList = (params) => axios.get('/ParkingLotApi/lnParking/GetParkingCostDetailPageList', { params: params })
// // 获取高位、视频桩、地磁设备列表
// export const getlnParkingDeviceList = (params) => {
//     return axios({
//         method: 'get',
//         url: '/ParkingLotApi/lnParking/GetlnParkingDeviceList',
//         params
//     })
// }
// 获取高位识别相机设备列表
export const getHighCameraList = (params) => axios.get('/ParkingLotApi/lnParking/GetDeviceHighCameraList', { params: params })
// 获取视频桩设备列表
export const getVideoPileList = (params) => axios.get('/ParkingLotApi/lnParking/GetDeviceVideoPileList', { params: params })
// 获取地磁设备列表
export const getGeomagneticList = (params) => axios.get('/ParkingLotApi/lnParking/GetDeviceGeomagneticList', { params: params })
// 获取路牙机设备列表
export const getDeviceMachineList = (params) => axios.get('/ParkingLotApi/lnParking/GetDeviceMachineList', { params: params })
// 获取停车场设备未配置车位列表
export const getlnParkingDeviceNotConfigList = (params) => axios.get('/ParkingLotApi/lnParking/GetlnParkingDeviceNotConfigList', { params: params })
// 获取停车场设备已配置车位列表
export const getlnParkingDeviceConfigList = (params) => axios.get('/ParkingLotApi/lnParking/GetlnParkingDeviceConfigList', { params: params })
// 停车场设备配置
export const setlnParkingDevice = (data) => axios.post('/ParkingLotApi/lnParking/SetlnParkingDevice', data)
// 获取停车场未绑定的计费规则列表
export const getParkingCostNotList = (params) => axios.get('/ParkingLotApi/lnParking/GetlnParkingCostNotConfigList', { params: params })
// 获取停车场已绑定的计费规则列表
export const getParkingCostConfigList = (params) => axios.get('/ParkingLotApi/lnParking/GetlnParkingCostConfigList', { params: params })
// 停车场计费规则配置
export const setlnParkingCost = (data) => axios.post('/ParkingLotApi/lnParking/SetlnParkingCost', data)
// 获取车位列表
export const getParkingSpaceList = (params) => axios.get('/ParkingLotApi/ParkingSpace/GetPageList', { params: params })
// 删除车位
export const delParkingSpace = (data) => axios.post('/ParkingLotApi/ParkingSpace/Delete', data)
// 新增车位
export const addParkingSpace = (data) => axios.post('/ParkingLotApi/ParkingSpace/Add', data)
// 编辑车位
export const updateParkingSpace = (data) => axios.post('/ParkingLotApi/ParkingSpace/Update', data)
// 判断车位编号是否重复
export const getParkingSpaceIsReuse = (params) => axios.get('/ParkingLotApi/ParkingSpace/VerifySpaceNumberIsReuse', { params: params })
// 新增车位时获取停车场权限
export const getParkingJurisdiction = (params) => axios.get('/ParkingLotApi/ParkingSpace/GetSpaceParkingList', { params: params })
